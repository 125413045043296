/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2020-11-11 18:07:24
 * @LastEditors: tina.cai
 * @LastEditTime: 2020-11-15 09:30:49
 */
import VueI18n from "vue-i18n"
import Vue from "vue"

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || "en-US", //语言标识
  messages: {
    "en-US": require("./locales/en-US.json"), // 英文
    "zh-cn": require("./locales/zh-cn.json"), // 中文
  },
  fallbackLocale: "en-US",
})
