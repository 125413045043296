/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2020-11-10 19:14:11
 * @LastEditors: tina.cai
 * @LastEditTime: 2020-11-15 09:18:20
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n.js"
import 'bootstrap'
import $ from 'jquery'

console.log($, '$')

Vue.config.productionTip = false

if (localStorage.lang !== undefined) {
  i18n.locale =  localStorage.getItem('lang')
} else {
  localStorage.setItem("lang", "en-US")
  i18n.locale = localStorage.getItem('lang')
}

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
